<template>
  <div class="secondContent view" bodyoffsetwidth="626">
    <div  class="template-search-selector">
      <div  class="template-search-selector-types">
        <div  class="template-search-selector-types-container">
          <div  class="template-types-selector-line template-search-selector-types-line">
            <p  class="template-types-selector-line-left">全部：</p>
            <div  class="template-types-selector-line-right" style="height: auto;">
              <div  class="template-types-selector-line-right-con hasPadding">
                <a  href="/tupian/wanshengjie-or0-pt0-pn1.html" class="template-types-selector-line-right-item active isText">全部</a>
                <a  href="/tupian/wanshengjie-pi20-si166-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">营销海报</a>
                <a  href="/tupian/wanshengjie-pi1-si216-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">新媒体配图</a>
                <a  href="/tupian/wanshengjie-pi2-si13-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">印刷物料</a>
                <a  href="/tupian/wanshengjie-pi3-si1-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">办公文档</a>
                <a  href="/tupian/wanshengjie-pi4-si34-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">社交生活</a>
                <a  href="/tupian/wanshengjie-pi7-si159-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">电商设计</a>
                <a  href="/tupian/wanshengjie-pi29-si178-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">个性定制</a>
                <a  href="/tupian/wanshengjie-pi12-si233-or0-pt0-pn1.html" class="template-types-selector-line-right-item isText">原创插画</a>
                <a  href="/tp-fk6-sk0?search=true" class="template-types-selector-line-right-item isText">正版图库</a>
                <div  class="template-types-selector-line-right-more-show" style="display: none;">更多
                  <i  class="lt-icon lt-icon-ios-arrow-down"></i>
                </div>
              </div>
            </div>
          </div>
          <div   class="template-types-selector-line template-search-selector-types-line recommend">
            <p  class="template-types-selector-line-left">您是不是想找：</p>
            <div  class="template-types-selector-line-right" style="height: 42px;">
              <div  class="template-types-selector-line-right-con hasPadding">
                <a  href="/tupian/wanshengjiexcd.html" class="template-types-selector-line-right-item isText">万圣节宣传单</a>
                <a  href="/tupian/wanshengjieylb.html" class="template-types-selector-line-right-item isText">万圣节易拉宝</a>
                <a  href="/tupian/wsjppt.html" class="template-types-selector-line-right-item isText">万圣节ppt</a>
                <a  href="/tupian/wsjfmt.html" class="template-types-selector-line-right-item isText">万圣节封面图</a>
                <a  href="/tupian/wsjchangtu.html" class="template-types-selector-line-right-item isText">万圣节长图</a>
                <a  href="/tupian/wsjyqhan.html" class="template-types-selector-line-right-item isText">万圣节邀请函</a>
                <a  href="/tupian/wanshengjiesucai.html" class="template-types-selector-line-right-item isText">万圣节素材</a>
                <a  href="/tupian/wjsjgzhtp.html" class="template-types-selector-line-right-item isText">万圣节公众号图片</a>
                <a  href="/tupian/wsjewma.html" class="template-types-selector-line-right-item isText">万圣节二维码</a>
                <a  href="/tupian/wanshengjiemp.html" class="template-types-selector-line-right-item isText">万圣节名片</a>
                <a  href="/tupian/wsjchahua.html" class="template-types-selector-line-right-item isText">万圣节插画</a>
                <a  href="/tupian/wsjywbj.html" class="template-types-selector-line-right-item isText">万圣节夜晚背景</a>
                <a  href="/tupian/wanshengjiehaibao.html" class="template-types-selector-line-right-item isText">万圣节海报</a>
                <a  href="/tupian/katongwsj.html" class="template-types-selector-line-right-item isText">卡通万圣节</a>
                <div  class="template-types-selector-line-right-more-show">更多
                    <i  class="lt-icon lt-icon-ios-arrow-down"></i>
                </div>
                <div  class="template-types-selector-line-right-more-show" style="display: none;">更多
                  <i  class="lt-icon lt-icon-ios-arrow-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="template-types-selector-sort">
        <a href="/tupian/wanshengjie-or0-pt2-ke万圣节-pn1.html" class="tempSort router-link-exact-active router-link-active checkSort">热门推荐</a>
        <a href="/tupian/wanshengjie-or1-pt2-ke万圣节-pn1.html" class="tempSort">最多使用</a>
        <a href="/tupian/wanshengjie-or2-pt2-ke万圣节-pn1.html" class="tempSort">最新上传</a>
        <section class="ckt-coupon">
          <div class="ckt-coupon_content">
            <i class="ckt-coupon_left">🔥</i>
            <span class="ckt-coupon_right">3000+精美简历模板！</span>
          </div>
        </section>
        <div class="rightType">
          <label class="free lt-checkbox-wrapper lt-checkbox-wrapper-checked lt-checkbox-large">
            <!--选中样式为： lt-checkbox-checked-->
            <span class="lt-checkbox lt-checkbox-checked">
              <span class="lt-checkbox-inner"></span> 
              <input type="checkbox" class="lt-checkbox-input">
            </span>免费
          </label>
          <label class="lt-checkbox-wrapper lt-checkbox-large">
            <span class="lt-checkbox">
              <span class="lt-checkbox-inner"></span> 
              <input type="checkbox" class="lt-checkbox-input">
            </span>会员免费
          </label>
        </div>
      </div>
    </div>
    <div class="result">
      <div class="image-view">
        <div class="preview-img-wrap__img">
          <div class="preview-image">
            <img  alt="万圣狂欢夜公众号新版首图" data-src="//imgpub.chuangkit.com/designTemplate/2018/10/25/440144809_thumb?v=1551408601000&amp;x-oss-process=image/resize,w_600/format,webp" src="//imgpub.chuangkit.com/designTemplate/2018/10/25/440144809_thumb?v=1551408601000&amp;x-oss-process=image/resize,w_600/format,webp" lazy="loaded">
            <!--<div class="template-block-img-mask">
              <span class="preview-tooltip">
                <i class="preview-tooltip_arrow">预览</i>
              </span>
              <div class="beSimilar">
                <i class="icon"></i>找相似
              </div>
            </div>-->
          </div>
          <div class="template-block-text">
            <a  href="javascript:void(0)" class="template-block-title" @click="previewImg">万圣狂欢夜公众号新版首图</a>
          </div>
        </div>
      </div>
      <div  class="paging temp-second-content-pagination" style="display: flex; justify-content: center;">
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="100"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
        currentPage: 2
    };
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  methods: {
      previewImg () {
          this.$router.push({
              path: '/materialPreview'
          })
      }
  }
};
</script>